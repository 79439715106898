import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import LanguageDetector from 'i18next-browser-languagedetector';
import en from '../locales/en.json'
import zh from '../locales/zh.json'

// customDomainDetector.js
const customDomainDetector = {
  name: 'customDomainDetector',
  lookup() {
/*    const domain = window.location.hostname;
    if (domain.endsWith('long-term-ai.cn')) {
      return 'zh'; // Chinese for .cn domain
    } else if (domain.endsWith('long-term.ai')) {
      return 'en'; // English for .ai domain
    }*/
    return 'zh'; // Default null if no matching domain
  },
  cacheUserLanguage(lng) {
    // No need to implement caching for the custom detector
  }
};


/*const customLanguageDetector = new LanguageDetector();
customLanguageDetector.addDetector(customDomainDetector);*/

const options = {
  order: ['cookie', 'localStorage', 'sessionStorage', 'customDomainDetector', 'navigator', 'path', 'htmlTag', 'querystring', 'subdomain'],
  lookupQuerystring: 'lng',
  lookupCookie: 'i18next',
  lookupLocalStorage: 'i18nextLng',
  lookupSessionStorage: 'i18nextLng',
  lookupFromPathIndex: 0,
  lookupFromSubdomainIndex: 0,
  caches: ['localStorage', 'cookie'],
  excludeCacheFor: ['cimode'],
  cookieMinutes: 10,
  cookieDomain: 'myDomain',
  htmlTag: document.documentElement,
  cookieOptions: { path: '/', sameSite: 'strict' }
};

i18n
  // .use(customLanguageDetector)
  .use(LanguageDetector)
  .use(initReactI18next)
  .init({
    detection: options,
    fallbackLng: 'zh',
    debug: true,
    resources: {
        en: en,
        zh: zh
    },
    interpolation: {
      escapeValue: false // not needed for react as it escapes by default
    }
  });

export default i18n;
